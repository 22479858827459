@charset "UTF-8";
/* =========================================================================================================

		新着ブログ一覧

============================================================================================================ */
/*======================= カテゴリリンク =======================*/
/* 全体 */
img {
  width: auto;
}

.list li {
  /*		border-bottom:1px solid #ccc;*/
  height: auto;
  width: 100%;
  display: table;
  border-bottom: 1px solid #ccc;
}

.list li:nth-of-type(1) {
  border-top: 2px solid #ccc;
}

/*.list li:last-of-type{
	border-bottom:2px solid #ccc;
}*/
.list li > div {
  display: table-cell;
}

.list .right_arrow {
  width: 6%;
  font-size: 40px;
  font-weight: 100;
  color: #bfbfbf;
  text-align: center;
  float: right;
  vertical-align: middle;
  margin-top: -35px;
}

/* smpの時左BOX */
.smp_left {
  float: none;
  width: 90%;
  max-width: 652px;
}

/* 横並び */
.blog_box {
  display: table-cell;
}

.blog_box div {
  /*	display:inline-block;*/
  line-height: 1.3;
  text-align: left;
}

.blog_box div.blogbutton_box {
  margin-top: 60px;
  text-align: center;
  display: inline-block;
}

.blog_box .wp-pagenavi {
  display: inline;
}

/* liごとに下線を付ける li上下余白 */
.blog_box li {
  width: 100%;
  font-size: 15px;
  color: #000000;
  padding: 15px 0;
  /*	border-top:solid 1px #bfbfbf;*/
}

/* 最後のliに下線を付ける */
.blog_box .last {
  border-bottom: solid 1px #bfbfbf;
  margin-bottom: 40px;
}

/* カテゴリー */
.blog_box .category {
  /*	width:100%;*/
  font-size: 15px;
  font-weight: bold;
  color: #4e8f53;
  vertical-align: middle;
  text-align: left;
  margin-left: 20px;
  display: inline-block;
}

/* 区切り線 */
.blog_box .line {
  width: 2%;
  font-size: 18px;
  font-weight: 100;
  text-align: right;
  color: #898989;
  vertical-align: middle;
}

/* 日付 */
.blog_box .time {
  /*	width:100%;*/
  font-size: 15px;
  vertical-align: middle;
  text-align: left;
  display: inline-block;
}

/* テキスト */
.blog_box .text {
  width: 100%;
  font-size: 15px;
  text-align: left;
  vertical-align: middle;
  /* テキスト省略 */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
}

.blog_box .smp_left .text {
  font-size: 20px;
  margin-top: 5px;
}

/* ＞矢印 */
.blog_box .right_arrow {
  width: 6%;
  font-size: 40px;
  font-weight: 100;
  color: #bfbfbf;
  text-align: center;
  float: right;
  vertical-align: middle;
  margin-top: -35px;
}

article .sns {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  margin-top: 10px;
  max-width: 285px;
  display: block;
  width: auto;
}
article .sns ul {
  margin-left: auto;
  margin-right: auto;
}
article .sns ul li {
  border: none;
  border-right: 1px dotted #ccc;
  padding-right: 15px;
  padding-left: 15px;
}
article .sns ul li:first-of-type {
  border-left: 1px dotted #ccc;
}
article .sns p {
  font-size: 12px;
  text-align: center;
  color: #333;
  margin-top: 10px;
  line-height: 1.2;
  letter-spacing: -1px;
}
article .sns + p {
  line-height: 1.6;
  margin-bottom: 60px;
}

.category_box {
  width: 100%;
  text-align: left;
  margin-top: 60px;
  margin-bottom: 20px;
}
.category_box > a {
  width: 32%;
  padding: 10px 0 10px 10px;
  border: 1px solid #D9D9D9;
  position: relative;
}
.category_box > a:first-child {
  float: left;
}
.category_box > a:nth-child(2) {
  float: left;
  margin-left: 2%;
}
.category_box > a:last-child {
  float: right;
}
.category_box > a a {
  display: block;
}
.category_box > a p:first-child {
  width: 88%;
  float: left;
  line-height: 22px;
}
.category_box > a p:last-child {
  width: 12%;
  float: right;
  text-align: center;
}
.category_box > a a p i {
  font-size: 20px;
  color: #bfbfbf;
}
.category_box > a + .w {
  position: absolute;
  height: auto;
  overflow: hidden;
  top: 20px;
}
.category_box > a + .w ul li {
  border-bottom: 1px dotted #ccc;
}

/* li 横並び */
/* リンク */
/* テキスト　横並び */
/* アイコン　横並び */
/* アイコン */
/*========================= ブログ一覧 =========================*/
/* 一覧全体 */
.main-content {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  word-break: break-all;
}

.blog_box02 {
  width: 100%;
  margin-top: 50px;
}
.blog_box02 ul {
  width: 100%;
  text-align: left;
  border-bottom: 1px solid #bfbfbf;
}
.blog_box02 ul a {
  display: block;
  transition-duration: 0.3s;
}
.blog_box02 ul a:hover {
  opacity: 0.7;
}
.blog_box02 li {
  width: 100%;
  padding-top: 17px;
  padding-bottom: 15px;
  padding-left: 20px;
  border-top: solid 1px #bfbfbf;
}
.blog_box02 .last {
  border-bottom: solid 1px #bfbfbf;
}
.blog_box02 div {
  display: inline-block;
}
.blog_box02 .category {
  width: 10%;
  font-size: 15px;
  font-weight: bold;
  color: #4e8f53;
  vertical-align: middle;
}
.blog_box02 .line {
  width: 2%;
  font-size: 20px;
  color: #898989;
  vertical-align: middle;
}
.blog_box02 .time {
  width: 9%;
  font-size: 15px;
  vertical-align: middle;
}
.blog_box02 .text {
  width: 75%;
  font-size: 15px;
  vertical-align: middle;
  line-height: 18px;
  /* テキスト省略 */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
}

/* liごとに下線を付ける */
/* 最後のliに下線を付ける */
/* 横並び */
/* カテゴリー */
/* 区切り線 */
/* 日付 */
/* テキスト */
/* ボタンBOX */
.blogbutton_box {
  width: 80%;
  margin: 80px auto 0;
}
.blogbutton_box li {
  width: 48%;
  display: inline-block;
  text-align: center;
}
.blogbutton_box li:first-child {
  display: inline-block;
}
.blogbutton_box li:nth-child(2) {
  display: inline-block;
}
.blogbutton_box a:hover {
  text-decoration: underline;
}

/* ボタン */
/* ボタン横並び 左 */
/* ボタン横並び 右 */
/* ボタンサイズとか */
.button {
  width: 47%;
  display: inline-block;
  margin: 0 auto;
  padding: 15px 0px;
  font-size: 14px;
  border-radius: 25px;
  background-color: #4e8f53;
  color: #fff;
  transiton-duration: 0.3s;
}
.button:nth-of-type(2) {
  margin-left: 3%;
}
.button:hover {
  opacity: .8;
}

.contents01_box {
  max-width: 1120px;
}

.w-content {
  display: table;
  width: 90%;
  margin-right: auto;
  margin-left: auto;
}
.w-content article {
  display: table-cell;
  vertical-align: top;
}
.w-content aside {
  padding-left: 20px;
  display: table-cell;
  width: 265px;
  vertical-align: top;
}
.w-content aside section {
  padding-top: 15px;
  padding-bottom: 15px;
  border-top: 2px solid #bfbfbf;
}
.w-content aside section h2 {
  width: 100%;
  text-align: left;
  color: #4e8f53;
  font-size: 16px;
  font-weight: bold;
  padding-bottom: 15px;
}
.w-content aside section p {
  line-height: 1.6;
  text-align: left;
}
.w-content aside section ul li {
  border-bottom: 1px dotted #ccc;
  line-height: 1.6;
  text-align: left;
  padding-top: 5px;
  padding-bottom: 5px;
}

/* =========================================================================================================

		767px以下

============================================================================================================ */
@media (max-width: 767px) {
  /*========================= ブログ一覧 =========================*/
  .contents01_box {
    width: 100%;
  }
  .contents01_box .w-content {
    display: block;
    width: 100%;
  }

  .blog_box {
    display: block;
  }
  .blog_box * {
    box-sizing: border-box;
  }
  .blog_box .text {
    white-space: normal;
  }

  .blog_box02 {
    margin-top: 30px;
  }
  .blog_box02 div {
    display: block;
  }
  .blog_box02 .line {
    display: none;
  }
  .blog_box02 .category {
    width: 100%;
    font-size: 13px;
    line-height: 20px;
  }
  .blog_box02 .time {
    width: 100%;
    font-size: 12px;
    line-height: 20px;
  }
  .blog_box02 .text {
    width: 100%;
    font-size: 12px;
    line-height: 18px;
  }
  .blog_box02 li {
    padding-top: 12px;
    padding-bottom: 10px;
    padding-left: 0;
  }

  /* 横並び 解除 */
  /* 区切り線 非表示 */
  /* カテゴリー */
  /* 日付 */
  /* テキスト */
  /* liごとに下線を付ける */
  /* ボタンBOX */
  .blogbutton_box {
    width: 90%;
    padding-top: 40px;
    margin-right: auto;
    margin-left: auto;
  }
  .blogbutton_box li {
    font-size: 12px;
  }

  /* ボタン横並び */
  /*========================= プルダウン =========================*/
  /* li 横並び 解除 */
  .category_box li {
    width: 100%;
    margin-bottom: 10px;
  }
  .category_box li:first-child {
    float: none;
  }
  .category_box li:nth-child(2) {
    float: none;
    margin-left: 0;
  }
  .category_box li:last-child {
    float: none;
  }
}
/* =========================================================================================================

		新着ブログ詳細

============================================================================================================ */
/* 全体 */
.blogContents_box {
  font-size: 15px;
  text-align: left;
  margin-right: auto;
  margin-left: auto;
  padding: 28px 0 0 0;
  border-top: 2px solid #bfbfbf;
  line-height: 1.6;
  word-break: break-all;
}
.blogContents_box br {
  line-height: 0;
}
.blogContents_box ul {
  width: 100%;
  margin-bottom: 20px;
}
.blogContents_box ul li {
  display: inline-block;
}
.blogContents_box ul .category {
  font-size: 16px;
  font-weight: bold;
  color: #4e8f53;
  padding-left: 20px;
  padding-right: 35px;
}
.blogContents_box ul .category span {
  color: #333 !important;
  font-weight: 500;
}
.blogContents_box ul .category h3 {
  display: block;
  padding-top: 10px;
  padding-bottom: 0;
  font-weight: bold;
}
.blogContents_box ul .line {
  font-size: 20px;
  color: #898989;
}
.blogContents_box ul .time {
  font-size: 16px;
  /*	padding-left:35px;*/
}
.blogContents_box h2 {
  font-size: 20px;
  margin-bottom: 20px;
}
.blogContents_box p {
  font-size: 15px;
  line-height: 22px;
  padding-bottom: 60px;
}
.blogContents_box p a {
  text-decoration: underline;
  color: #ed6d00;
  transition-duration: 0.3s;
}
.blogContents_box p a:hover {
  opacity: 0.7;
}

/* ul 上線 */
/* 項目横並び */
/* カテゴリー */
/* 区切り線 */
/* 日付 */
/* h2 */
/* テキスト */
@media (max-width: 1120px) {
  .contents01_box {
    max-width: none;
  }

  .w-content article {
    width: auto;
  }
  .w-content aside section {
    padding-top: 15px;
    padding-bottom: 15px;
    border-top: 1px solid #bfbfbf;
  }
  .w-content aside section h2 {
    width: 100%;
    text-align: left;
    color: #4e8f53;
    font-size: 16px;
    font-weight: bold;
    padding-bottom: 15px;
  }
  .w-content aside section p {
    line-height: 1.6;
    text-align: left;
  }
  .w-content aside section ul li {
    border-bottom: 1px dotted #ccc;
    line-height: 1.6;
    text-align: left;
    padding-top: 5px;
    padding-bottom: 5px;
  }
}
/* =========================================================================================================

		767px以下

============================================================================================================ */
@media (max-width: 767px) {
  /* ul 上線 */
  img {
    max-width: 100%;
    height: auto;
  }

  .blogContents_box {
    font-size: 12px;
  }
  .blogContents_box ul {
    padding-top: 14px;
    margin-bottom: 14px;
  }
  .blogContents_box ul li {
    display: block;
  }
  .blogContents_box ul .line {
    display: none;
  }
  .blogContents_box ul .category {
    font-size: 13px;
    line-height: 20px;
    padding-left: 0;
    padding-right: 0;
  }
  .blogContents_box ul .time {
    font-size: 12px;
    line-height: 20px;
    padding-left: 0;
  }
  .blogContents_box h2 {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 20px;
  }
  .blogContents_box p {
    font-size: 12px;
    line-height: 18px;
    word-wrap: break-word;
  }

  /* 項目横並び解除 */
  /* 区切り線 非表示 */
  /* カテゴリー */
  /* 日付 */
  /* h2 */
  /* テキスト */
  .w-content article {
    width: auto;
    display: block;
  }
  .w-content article .button {
    width: 80%;
  }
  .w-content aside {
    margin-top: 40px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-left: 0;
    display: block;
  }
  .w-content aside section {
    padding-top: 15px;
    padding-bottom: 15px;
    border-top: 1px solid #bfbfbf;
  }
  .w-content aside section h2 {
    width: 100%;
    text-align: left;
    color: #4e8f53;
    font-size: 16px;
    font-weight: bold;
    padding-bottom: 15px;
  }
  .w-content aside section p {
    line-height: 1.6;
    text-align: left;
  }
  .w-content aside section ul li {
    border-bottom: 1px dotted #ccc;
    line-height: 1.6;
    text-align: left;
    padding-top: 5px;
    padding-bottom: 5px;
  }
}
.contents01_box .prologe {
  line-height: 1.6;
  margin-bottom: 30px;
}

.wp_social_bookmarking_light .wsbl_twitter {
  width: 68px;
}
.wp_social_bookmarking_light .wsbl_line img {
  width: auto;
  max-height: 20px;
}


